import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import * as BsIcons from "react-icons/bs";
import actions from "../../../redux/actions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { formattedDateTime } from "../../../utilities";
import { useNavigate } from "react-router-dom";

const AccountStatements = () => {
  let navigate = useNavigate();
  const accountStatementList = useSelector(
    (state) => state.reportSlice?.accountStatementDetails
  );
  const [data, setData] = useState({
    skipRec: 0,
    type: "All",
    psize: 10,
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    sportsId: 0,
  });
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    let data = {
      skipRec: 0,
      type: "All",
      psize: 10,
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sportsId: 0,
    };
    actions.getAccountStatement(data);
  }, []);

  useEffect(() => {
    if (
      accountStatementList?.Result &&
      Array.isArray(accountStatementList?.Result) &&
      accountStatementList?.Result?.length > 0
    ) {
      let totalRow = accountStatementList?.Result[0]?.SqlCount;
      setTotalPage(Math.ceil(totalRow / 10));
    } else {
      setTotalPage(0);
    }
  }, [accountStatementList]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleResetData = (e) => {
    e.preventDefault();
    setData({
      ...data,
      type: "All",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sportsId: 0,
    });
  };

  const callAccountAction = (e) => {
    e.preventDefault();
    actions.getAccountStatement(data);
  };

  const handlePageNoChange = (newPageNo) => {
    setData((prevState) => ({ ...prevState, skipRec: newPageNo }));
    actions.getAccountStatement({ ...data, skipRec: newPageNo });
  };

  //   const DynamicPagination = (number) => {
  //     let divCount = number;
  //     if (number > 4) {
  //       divCount = 4;
  //     }

  //     const divs = [];
  //     for (let i = number - divCount + 1; i <= number; i++) {
  //       divs.push(
  //         <li key={i} class="page-item me-1">
  //           <button
  //             class={`${
  //               number === i
  //                 ? "pagination-number "
  //                 : "pagination-inactive-number "
  //             }`}
  //             onClick={() => {
  //               setData((prevState) => ({ ...prevState, skipRec: i * 10 }));
  //             }}
  //           >
  //             {i}
  //           </button>
  //         </li>
  //       );
  //     }
  //     return <>{divs}</>;
  //   };

  const DynamicPagination = (number) => {
    let divCount = number;
    if (number > 4) {
      divCount = 4;
    }
    const divs = [];
    for (let i = number - divCount + 1; i <= number; i++) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              data?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                skipRec: i,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        {" "}
        {/* main content wrapper */} {/* Apply the custom border color */}
        <div className="content-header d-flex justify-content-between">
          <span className="">Account Statement</span>
        </div>
        <div className="row ">
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">Type</label>
            <select
              className="form-select custom-input"
              value={data?.type}
              name="type"
              onChange={handleChange}
            >
              <option value="All">All</option>
              <option value="FreeChips">Balance</option>
              <option value="Settlement">Settlement</option>
              <option value="ProfitLoss">Profit/Loss</option>
            </select>
          </div>
          {/*  <div className="col-6 hide col-md-4 col-lg-3 mt-2">
                        <label className="form-label white-input-label mb-0">Sports List</label>
                        <select className="form-select custom-input" value={data?.sportsId} name="sportsId" onChange={handleChange}>
                            <option value='0'>All</option>
                            <option value='4'>Cricket</option>
                            <option value='1'>Soccer</option>
                            <option value='2'>Tennis</option>
                        </select>
          </div>*/}
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose from Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.startDate}
              name="startDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose to Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.endDate}
              name="endDate"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-6 col-md-4 col-lg-3 mt-2">
          <button
            type="button"
            className="btn btn-sm violet_button me-2"
            onClick={callAccountAction}
          >
            Go
          </button>
          <button
            type="button"
            className="btn btn-sm grey_button"
            onClick={handleResetData}
          >
            <BsIcons.BsEraserFill /> Reset
          </button>
        </div>
        <div className="row text-white">
          <div className="col-5 col-md-5 col-lg-5 mt-2">
            Opening Balance :{accountStatementList?.open}
          </div>
          <div className="col-5 col-md-5col-lg-5 mt-2">
            Closing Balance : {accountStatementList?.close}{" "}
          </div>
        </div>
        <div className="table-responsive mt-2">
          <div className="table-wrapper">
            <table className="table table-striped">
              <thead>
                <th>Date</th>
                <th>Amount</th>
                <th> Balance</th>
                <th>Remarks</th>
                <th>Description</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td className="dark-table-cell"> </td>
                  <td className="dark-table-cell"> </td>
                  <td className="dark-table-cell">
                    {accountStatementList?.close}{" "}
                  </td>
                  <td className="dark-table-cell long-row-td"> </td>
                  <td className="dark-table-cell"> Closing balance </td>
                  <td className="dark-table-cell"> </td>
                </tr>
                {accountStatementList?.Result &&
                Array.isArray(accountStatementList?.Result) &&
                accountStatementList?.Result?.length > 0 ? (
                  accountStatementList?.Result?.map((item, index) => (
                    <tr key={index}>
                      <td className="dark-table-cell">
                        {" "}
                        {formattedDateTime(item?.createdOn)}
                      </td>
                      <td
                        className={`${
                          item?.Amount < 0
                            ? "dark-table-cell red-text"
                            : "dark-table-cell green-text"
                        }`}
                      >
                        {item?.Amount}{" "}
                      </td>
                      <td className="dark-table-cell">{item?.Balance} </td>
                      <td className="dark-table-cell long-row-td">
                        {
                          item?.MarketType
                          /*
                        item?.Remark
                        */
                        }
                      </td>
                      <td className="dark-table-cell">{item?.Discription} </td>
                      {item?.EventId.length < 3 ? (
                        <td className="dark-table-cell"></td>
                      ) : (
                        <td
                          className="dark-table-cell active-link"
                          onClick={() =>
                            navigate(`/betting_history/${item?.EventId}/0`, {
                              state: {
                                eventid: 0,
                                SelectionID: "",
                                marketType: item?.MarketType,
                              },
                            })
                          }
                        >
                          show bet
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="6">
                      No Result
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {accountStatementList?.Result &&
              accountStatementList?.Result?.length > 0 && (
                <nav aria-label="Page navigation example" className="me-1">
                  <ul class="pagination justify-content-start mb-1">
                    <li class="page-item me-1">
                      <button
                        class="page-link pagination-icon"
                        disabled={data?.skipRec === 0 ? true : false}
                        onClick={() => {
                          handlePageNoChange(data?.skipRec - 1);
                        }}
                      >
                        Previous
                      </button>
                    </li>

                    {DynamicPagination(totalPage)}

                    <li class="page-item">
                      <button
                        class="page-link pagination-icon"
                        disabled={
                          data?.skipRec === totalPage - 1 ? true : false
                        }
                        onClick={() => {
                          handlePageNoChange(data?.skipRec + 1);
                        }}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default AccountStatements;
