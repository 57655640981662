import React from "react";
import { useNavigate } from "react-router-dom";
import { sliderImg } from "./sliderImg";
import BannerSlider from "../../components/BannerSlider";
import { useSelector } from "react-redux";

const InfoBranding = () => {
  const news = useSelector((state) => state.marketSlice?.newsData?.Result);
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login");
  };
  const getHostname = () => {
    try {
      var host = window.location.hostname;
      //const host = new URL(url).host;
      const dots = host.split(".");
      return dots.at(-2).replace(/\d/g, "");
    } catch (ex) {}
  };
  return (
    <>
      <header
        className={`pk d-flex flex-column align-items-center position-fixed ${getHostname()} `}
      >
        <div className="container-fluid d-flex align--center py-1">
          <div className="d-flex align-items-center logo_area ps-2 ps-md-3 ps-lg-0">
            <img
              src={`/assets/images/${getHostname()}.png`}
              alt="logo"
              className="w-100"
            />
          </div>
          <div className="right_area d-flex align-items-center order-lg-3 ms-auto ps-2">
            <ul className="ps-0">
              <li className="open">
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn loginBtn text-white f-14"
                    onClick={handleLoginClick}
                  >
                    Login
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="marquee_area d-flex align-items-center w-100">
          {" "}
          <span className="ic">
            {" "}
            <img src="./assets/img/speaker_blue.png" alt="" />{" "}
          </span>
          <div className="scrolling-text">{news ? news[0]?.Message : ""}</div>
        </div>
      </header>
      <div className="content-wrapper me-0 full-width">
        {" "}
        {/* main content wrapper */}
        <div className="d-flex flex-wrap">
          <BannerSlider slides={sliderImg} />
          <div className="tag-container">
            <div className="tag-text">trending games</div>
          </div>
          <div className="row g-1">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 px-1 mb-2">
              <div className="gameCard">
                <img
                  src="/assets/images/cricket-home.jpg"
                  className="img-fluid"
                  alt="cricket"
                />
                <div className="gameName">Cricket</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 px-1 mb-2">
              <div className="gameCard">
                <img
                  src="/assets/images/football-home.jpg"
                  className="img-fluid"
                  alt="football"
                />
                <div className="gameName">Soccer</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 px-1 mb-2">
              <div className="gameCard">
                <img
                  src="/assets/images/tennis-home.jpg"
                  className="img-fluid"
                  alt="tennis"
                />
                <div className="gameName">Tennis</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 px-1 mb-2">
              <div className="gameCard">
                <img
                  src="/assets/images/casino-home.jpg"
                  className="img-fluid"
                  alt="Casino"
                />
                <div className="gameName">Casino</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoBranding;
